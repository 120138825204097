import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158')
];

export const server_loads = [2];

export const dictionary = {
		"/[[region]]/(main)/(static)/about": [25,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/affiliate": [26,[2,3,5],[,4]],
		"/[[region]]/auth-screen": [153,[2,20]],
		"/[[region]]/(main)/blog": [42,[2,3,7],[,4]],
		"/[[region]]/(main)/blog/[slug]": [43,[2,3,7],[,4]],
		"/[[region]]/business": [154,[2,21]],
		"/[[region]]/business/article-playlink-injection": [155,[2,21]],
		"/[[region]]/business/data-services": [156,[2,21]],
		"/[[region]]/(main)/(static)/cookies": [27,[2,3,5],[,4]],
		"/[[region]]/(main)/delete-account": [44,[2,3,8],[,4]],
		"/[[region]]/(main)/dennis": [45,[2,3,9],[,4]],
		"/[[region]]/(main)/design-system": [46,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/alert-message": [47,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/app-download": [48,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/bricks": [49,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/button-bar": [50,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/buttons": [51,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/cards": [52,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/checkbox-buttons": [53,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/checkbox-tags": [54,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/checkboxes-with-search": [55,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/closable": [56,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/colors": [57,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/conditional-component": [58,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/context-menu": [59,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/css-variables": [60,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/cursorphobic": [61,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/delay-render": [62,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/double-content-panel": [63,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/draggable-progress-bar": [64,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/dropdown-filter": [65,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/dual-range-slider": [66,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/empty": [67,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/error-toasts": [68,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/evaluate-search": [69,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/expandable-description": [70,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/explore": [71,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/external-partner": [72,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/fold-out": [73,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/fonts": [74,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/grid": [75,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/hero": [76,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/horizontal-list": [77,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/image-fan": [78,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/inputs": [79,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/item-content": [80,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/mixins": [81,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/modal": [82,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/my-library": [83,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/navigation": [84,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/notifications": [85,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/number-ticker": [86,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/onboarding-block": [87,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/onboarding-simple-recommendations": [88,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/playlinks": [89,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/playlist-cards": [90,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/playlists": [91,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/radio-buttons": [92,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/scroll-distance": [93,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/shadows": [94,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/similarity-badge": [95,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/skeleton": [96,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/spinner": [97,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/suck-into-library": [98,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/swipeable": [99,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/tabs": [100,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/takeover": [101,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/timeout-bar": [102,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/toggleable-tooltip": [103,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/toggles-with-search": [104,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/universal-dropdown": [105,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/universal-tooltip": [106,[2,3,10],[,4]],
		"/[[region]]/(main)/design-system/user-avatar": [107,[2,3,10],[,4]],
		"/[[region]]/(main)/discover-weekly": [108,[2,3],[,4]],
		"/[[region]]/(main)/email-settings/[signed_uuid]": [109,[2,3],[,4]],
		"/[[region]]/(main)/(static)/eula": [28,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/faq": [29,[2,3,5],[,4]],
		"/[[region]]/(main)/(static)/guidelines": [30,[2,3,5],[,4]],
		"/[[region]]/(main)/invite/[username]": [110,[2,3],[,4]],
		"/[[region]]/(main)/library/(content)": [111,[2,3,11,12],[,4]],
		"/[[region]]/(main)/library/(content)/[filter]": [112,[2,3,11,12],[,4]],
		"/[[region]]/maintenance": [157,[2,22]],
		"/[[region]]/(main)/name/[sid]-[name]": [113,[2,3],[,4]],
		"/[[region]]/(main)/(static)/newsletter/unsubscribed": [~31,[2,3,5],[,4]],
		"/[[region]]/(main)/notifications": [114,[2,3],[,4]],
		"/[[region]]/(main)/partner": [115,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/api": [116,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/api/playlinks": [117,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/languages-regions": [118,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/link-in-bio": [119,[2,3,14],[,4]],
		"/[[region]]/(main)/partner/link-in-bio/edit/[[sid]]": [120,[2,3,14],[,4]],
		"/[[region]]/(main)/partner/link-injections": [121,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/on-page": [122,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/on-page/configuration": [123,[2,3,13],[,4]],
		"/[[region]]/(main)/partner/on-page/customization": [124,[2,3,13],[,4]],
		"/[[region]]/(main)/playlists": [126,[2,3],[,4]],
		"/[[region]]/(main)/playlist/[playlistId]": [125,[2,3],[,4]],
		"/[[region]]/(main)/(static)/policy": [32,[2,3,5],[,4]],
		"/[[region]]/(main)/profile/hidden": [127,[2,3,15],[,4]],
		"/[[region]]/(main)/profile/ratings": [128,[2,3,15],[,4]],
		"/[[region]]/(main)/profile/saved": [129,[2,3,15],[,4]],
		"/[[region]]/(main)/profile/services": [130,[2,3,15],[,4]],
		"/[[region]]/(main)/profile/settings": [131,[2,3,15],[,4]],
		"/[[region]]/(main)/profile/tracked": [132,[2,3,15],[,4]],
		"/[[region]]/(main)/rate-titles": [133,[2,3],[,4]],
		"/[[region]]/(main)/recommendations": [134,[2,3,16],[,4]],
		"/[[region]]/replay/[username]": [158,[2,23]],
		"/[[region]]/(main)/reset-password": [135,[2,3,17],[,4]],
		"/[[region]]/(main)/reviews/[slug]": [136,[2,3],[,4]],
		"/[[region]]/(main)/russia-temporarily-unavailable": [137,[2,3],[,4]],
		"/[[region]]/(main)/search": [138,[2,3],[,4]],
		"/[[region]]/(main)/similar-to/[sid]": [139,[2,3],[,4]],
		"/[[region]]/(main)/social": [140,[2,3],[,4]],
		"/[[region]]/(main)/(static)/stepler": [33,[2]],
		"/[[region]]/(main)/users": [147,[2,3,19],[,4]],
		"/[[region]]/(main)/users/follow-requests": [148,[2,3,19],[,4]],
		"/[[region]]/(main)/users/followers": [149,[2,3,19],[,4]],
		"/[[region]]/(main)/users/following": [150,[2,3,19],[,4]],
		"/[[region]]/(main)/users/similar": [151,[2,3,19],[,4]],
		"/[[region]]/(main)/users/synced-contacts": [152,[2,3,19],[,4]],
		"/[[region]]/(main)/user/uuid/[uuid]": [146,[2,3],[,4]],
		"/[[region]]/(main)/user/[username]": [141,[2,3,18],[,4]],
		"/[[region]]/(main)/user/[username]/followers": [142,[2,3,18],[,4]],
		"/[[region]]/(main)/user/[username]/following": [143,[2,3,18],[,4]],
		"/[[region]]/(main)/user/[username]/ratings": [144,[2,3],[,4]],
		"/[[region]]/(main)/user/[username]/reviews": [145,[2,3,18],[,4]],
		"/[[region]]/(main)/[category=categories]": [35,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/service/[service]": [37,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/service/[service]/[genre=genres]": [38,[2,3,6],[,4]],
		"/[[region]]/(main)/[category=categories]/[genre=genres]": [36,[2,3,6],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]": [39,[2,3],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]/customize": [40,[2,3],[,4]],
		"/[[region]]/(main)/[contentType=contentTypes]/[slug]/season-[season]": [41,[2,3],[,4]],
		"/[[region]]/(main)": [24,[2,3],[,4]],
		"/[[region]]/(main)/[...path]": [34,[2,3],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';